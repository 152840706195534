import React, { useEffect, useMemo, useRef, useState } from 'react';
import type { GetServerSideProps, NextPage } from 'next';
import Link from 'next/link';
import PhoneInput from 'components/CustomPhoneInput/NewPhoneComp';
import { Button } from 'components/Button';
import CustomSelectInput from 'components/CustomSelectInput';
import CustomInput from 'components/CustomInput';
import { event } from 'components/GoogleTagManager';
import parsePhoneNumber from 'libphonenumber-js';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import { useLoading } from 'hooks/useLoading';
import { useRouter } from 'next/router';
import { successToast } from 'lib/taost';
import { hmg_login, verify_otp } from 'models/customer';
import useRtl from 'hooks/useRtl';
import useLocalStorage from 'hooks/useLocalStorage';
import { parseMyNumber } from 'lib/helpers/functions/NumberParser';
import useCookieStorage from 'hooks/useCookieStorage';
import nookies from 'nookies';
import { serverSideRedirect } from 'lib/serverSideRedirect';
import useCart from 'hooks/useCart';
import { OTP_SEND_COUNTDOWN, START_DURATION, START_MINUTES, START_SECOND } from 'lib/constants';
import { OtpCounter } from 'components/OtpCounter';
import { NationalityIcon } from 'components/SvgIcons/NationalityIcon';
import { UserIcon } from 'components/SvgIcons/UserIcon';
import { PhoneIcon } from 'components/SvgIcons/PhoneIcon';
import { OTPIcon } from 'components/SvgIcons/OTPIcon';
import { apolloErrorToast } from 'utils/apollo-error-toast';
import { convertPhoneNumber } from 'utils/lokalise.util';
import Turnstile from 'utils/cloudflare-turnstile-recapcha';
import { initializeTurnstile, loadTurnstileScript } from 'utils/cloudflare-turnstile-recapcha-logic';
import { trackPageEvent } from 'utils/content-squire';
import { useUserLoggedStatus } from 'hooks/useUserLoggedStatus';

const Login: NextPage = () => {
  const { t } = useTranslation('common');
  const [loading, setLoading] = useLoading();
  const { getCartData } = useCart();
  const [, setIsLoggin] = useCookieStorage('isCustomerLoggedIn', false);
  const [, setZipCode] = useLocalStorage('zip_code', '');
  const [, setPhoneNo] = useLocalStorage('phone_no', '');
  const [, setNationalId] = useLocalStorage('national_id', '');
  const [otp, setOTP] = useState<{ otp_code: string; otp_id: number }>();
  const [error, setError] = useState({
    otp: '',
    phoneNumber: '',
    national_id: '',
    generalError: '',
    type: '',
  });
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [national_id, setNationalID] = useState<string>('');
  const [type, setType] = useState<string>('national_id');
  const [code, setCode] = useState('');
  const { push, query } = useRouter();
  const { redirect, phone } = query;
  const isRtl = useRtl();
  const convertedPhoneNumber = convertPhoneNumber(phoneNumber, isRtl);
  const [reCaptchaToken, setReCaptchaToken] = useState<string>('');
  const [reCAPTCHAError, setReCAPTCHAError] = useState<string>('');
  const [showReCaptcha, setShowReCaptcha] = useState<boolean>(false);
  const [currentMinutes, setMinutes] = useState(START_MINUTES);
  const [currentSeconds, setSeconds] = useState(START_SECOND);
  const [duration, setDuration] = useState(START_DURATION);
  const [isNavigateCaptcha, setIsnavigateCaptcha] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState(false);
  const widgetRef = useRef<any>(null);

  useEffect(() => {
    if (phone) setPhoneNumber(phone);
  }, []);

  const isWindow = typeof window !== 'undefined';

  const resetHandler = (minutes, seconds) => {
    setMinutes(minutes);
    setSeconds(seconds);
    setIsRunning(false);
    setDuration(START_DURATION);
  };

  const startHandler = () => {
    setDuration(parseInt(START_SECOND, 10) + 60 * parseInt(START_MINUTES, 10));
    setIsRunning(true);
  };

  useEffect(() => {
    if (isRunning === true) {
      let timer = duration;
      let minutes, seconds;
      const interval = setInterval(function () {
        if (--timer <= 0) {
          resetHandler('00', '00');
        } else {
          minutes = parseInt(`${timer / 60}`, 10);
          seconds = parseInt(`${timer % 60}`, 10);

          minutes = minutes < 10 ? '0' + minutes : minutes;
          seconds = seconds < 10 ? '0' + seconds : seconds;

          setMinutes(minutes);
          setSeconds(seconds);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isRunning]);
  const { user_logged_status } = useUserLoggedStatus();

  useEffect(() => {
    if (isWindow) {
      trackPageEvent(window, 'LoginWithHMGPageView', {
        user_logged_status,
      });

      const Insider = window as Window | any;

      Insider.insider_object = Insider.insider_object || {};
      Insider.insider_object.page = {
        type: 'LoginWithHMGPageView',
      };
    }
  }, []);

  useEffect(() => {
    setReCAPTCHAError('');
  }, [reCaptchaToken]);

  const handleLogin = async (token?: any) => {
    if (phoneNumber && type && national_id && error.phoneNumber === '' && phoneNumber.length >= 12) {
      if (!token) {
        return;
      }
      const parsedPhoneNumber = parsePhoneNumber('+' + phoneNumber);
      const Insider = window as Window | any;
      const { numberZip, numberItself } = parseMyNumber(phoneNumber);
      if (parsedPhoneNumber?.isValid) {
        setLoading(true);
        try {
          setError((prev) => ({ ...prev, otp: '', type: '', phoneNumber: '', national_id: '' }));
          const data = await hmg_login(numberZip, numberItself, national_id, type, token);
          setZipCode(numberZip);
          setPhoneNo(numberItself);
          type === 'national_id' ? setNationalId(national_id) : setNationalId('');
          setOTP(data);
          setCode(data.otp_code ?? '');
          startHandler();
          event('profile_login', { login_method: 'HMG Account' });
          successToast(t('otp_sent'));
          Insider.insider_object = Insider.insider_object || {};
          Insider.insider_object.login = { code: numberZip, number: numberItself, nationalId: national_id, type };
        } catch (err: any) {
          setError((prev) => ({
            ...prev,
            generalError:
              err?.response?.data?.message === 'Customer not found'
                ? t('customer_not_found')
                : err?.response?.data?.message,
          }));
          event('profile_login_error', {
            login_error: 'error while logging in with HMG Account.',
          });
        } finally {
          setLoading(false);
        }
      } else {
        setError((prev) => ({ ...prev, phoneNumber: 'invalid_phone_number' }));
      }
    } else {
      if (!national_id)
        setError((prev) => ({
          ...prev,
          national_id: 'this_field_is_required',
        }));
      if (!phoneNumber)
        setError((prev) => ({
          ...prev,
          phoneNumber: 'this_field_is_required',
        }));
    }
  };

  const handleVerifyOTP = async () => {
    if (code.length === 6 || code.length === 4) {
      if (!reCaptchaToken) {
        return;
      }
      setLoading(true);
      try {
        const res = await verify_otp({ ...otp, otp_code: code });
        setIsLoggin(res?.id);
        document.body.classList.remove('overflow-hidden');
        await getCartData();
        if (res?.auto_created) {
          redirect ? push(`/signup?redirect=${redirect}`) : push('/signup');
        } else {
          redirect ? push(`/${redirect}`) : push('/');
        }
      } catch (err: any) {
        console.log('Error while verifying otp code');
        console.log('Error', err?.response?.data?.message);
        apolloErrorToast({ message: err?.response?.data?.message, Api: err?.config?.url ?? '' });
        setError((prev) => ({
          ...prev,
          otp: 'verification_code_is_incorrect',
        }));
      } finally {
        setLoading(false);
      }
    } else {
      setError((prev) => ({ ...prev, otp: 'enter_a_valid_otp' }));
    }
  };

  const handleResendOTP = async () => {
    setIsnavigateCaptcha(true);
    setReCaptchaToken('');
    setReCAPTCHAError('');

    // Hide Turnstile component to ensure cleanup
    setShowReCaptcha(false);

    // Wait a bit before re-showing to ensure cleanup
    setTimeout(async () => {
      setShowReCaptcha(true);

      // Load the Turnstile script if not already loaded
      await loadTurnstileScript();

      if (widgetRef.current) {
        try {
          const token = await initializeTurnstile(widgetRef, setReCaptchaToken);
          setOTP(undefined);

          handleLogin(token);
        } catch {
          setReCAPTCHAError('Recaptcha failed to initialize');
        }
      } else {
        console.log('widgetRef is not available');
      }
    }, 100); // Adjust the delay as needed to ensure proper cleanup
  };

  const renderer = (
    <OtpCounter currentMinutes={currentMinutes} currentSeconds={currentSeconds} handleLogin={handleResendOTP} />
  );

  useEffect(() => {
    const Insider = window as Window | any;

    Insider.insider_object = Insider.insider_object || {};
    Insider.insider_object.page = {
      type: 'Login',
    };
  }, []);

  console.log('otp...');
  return (
    <>
      <div className='hero container max-w-screen-lg mx-auto w-[90%] xl:w-[50%] rounded-2xl mt-20 flex justify-start bg-apollo-white'>
        <div className={`px-5 mb-3 w-full pr-5 ${isRtl && 'pt-4'}`}>
          <h1 className='font-bold text-[20px] pb-2 pt-2'>{t('login_with_hmg_account')}</h1>
          {otp && (
            <>
              <p data-cs-mask className='text-[16px] pb-2'>
                {t('to_continue_enter_6_digit')} {convertedPhoneNumber}
              </p>
            </>
          )}
          {error.generalError && (
            <small className='block mt-1 ml-1 text-[10px] text-apollo-error'>{error.generalError}</small>
          )}
          <p className='text-[10px] mb-2'>{t('choose_login_type')}</p>
          {/*  Form start */}
          <div className='form-group mb-3'>
            <div className='flex flex-col gap-3'>
              <CustomSelectInput
                value={type}
                name='type'
                placeHolder='select_type'
                options={[
                  {
                    label: t('national_id_lqama_no_passport'),
                    value: 'national_id',
                    key: 1,
                  },
                  {
                    label: t('file_number'),
                    value: 'file_no',
                    key: 2,
                  },
                ]}
                onChange={(e) => {
                  if (e.target.value) {
                    setType(e.target.value);
                    setNationalID('');
                    setOTP(undefined);
                    setError((prev) => ({
                      ...prev,
                      type: '',
                      generalError: '',
                    }));
                  } else {
                    setType('');
                    setNationalID('');
                    setError((prev) => ({
                      ...prev,
                      type: 'this_field_is_required',
                    }));
                  }
                }}
                error={error.type}
                Icon={NationalityIcon}
              />
              <CustomInput
                type='number'
                placeholder={type === 'national_id' ? 'id_number' : type === 'file_no' ? 'your_file_no' : ''}
                value={national_id}
                error={error.national_id}
                Icon={UserIcon}
                onChange={(e) => {
                  if (e.target.value) {
                    if (e.target.value.length > 10 && type === 'national_id') {
                      return;
                    }
                    if (e.target.value.length > 7 && type === 'file_no') {
                      return;
                    }
                    setNationalID(e.target.value);
                    setError((prev) => ({
                      ...prev,
                      national_id: '',
                      generalError: '',
                    }));
                  } else {
                    setNationalID('');
                    setError((prev) => ({
                      ...prev,
                      national_id: 'this_field_is_required',
                    }));
                  }
                }}
              />
            </div>
            <div
              className={`grid mt-3 ${
                otp ? 'xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-3' : 'grid-cols-1'
              }`}
            >
              <PhoneInput
                value={phoneNumber}
                Icon={PhoneIcon}
                error={error.phoneNumber}
                onChange={(e) => {
                  setOTP(undefined);
                  if (e) {
                    if (e.startsWith('966') && e.length >= 12) {
                      if (!error.national_id) setShowReCaptcha(true);
                      setPhoneNumber(e);
                      setError((prev) => ({
                        ...prev,
                        phoneNumber: '',
                        otp: '',
                        generalError: '',
                      }));
                    } else {
                      setPhoneNumber(e);
                      setError((prev) => ({
                        ...prev,
                        phoneNumber: 'invalid_phone_number',
                        otp: '',
                        generalError: '',
                      }));
                    }
                  } else {
                    setPhoneNumber('');
                    setError((prev) => ({
                      ...prev,
                      phoneNumber: 'this_field_is_required',
                      generalError: '',
                    }));
                  }
                }}
              />
              {otp && (
                <CustomInput
                  Icon={OTPIcon}
                  type='number'
                  inputMode='numeric'
                  placeholder='enter_6_digit_code'
                  value={code}
                  error={error.otp}
                  onChange={(e) => {
                    if (e.target.value) {
                      if (!isNaN(+e.target.value) && e.target.value.length < 7) {
                        if (!error.phoneNumber) setShowReCaptcha(true);
                        setCode(e.target.value);
                        setError((prev) => ({ ...prev, otp: '' }));
                      }
                    } else {
                      setCode('');
                      setError((prev) => ({
                        ...prev,
                        otp: 'enter_a_valid_otp',
                      }));
                    }
                  }}
                />
              )}
            </div>
            {otp && renderer}

            {showReCaptcha && phoneNumber && !error.phoneNumber && !error.national_id && (
              <Turnstile
                isNavigateCaptcha={isNavigateCaptcha}
                handleLogin={handleLogin}
                widgetRef={widgetRef}
                setReCaptchaToken={setReCaptchaToken}
                setReCAPTCHAError={setReCAPTCHAError}
              />
            )}
            {reCAPTCHAError && <span className='text-error text-[12px] m-0 p-0'>{reCAPTCHAError}</span>}
          </div>
          <Button
            fullWidth
            onClick={() => {
              if (otp) handleVerifyOTP();
              else if (reCaptchaToken && !reCAPTCHAError) {
                setShowReCaptcha(false);
                handleLogin(reCaptchaToken);
              }
            }}
            title={t('login') ?? ''}
          />
        </div>
      </div>
      <p className='text-[14px] text-apollo-primary pt-6 text-center cursor-pointer'>
        <Link href={redirect ? `/login-with-number?redirect=${redirect}` : '/login-with-number'} prefetch={false}>
          <span
            onClick={() => {
              setIsLoggin(false);
              event('continued_to_login', { login_method: 'phone number' });
            }}
          >
            {t('continue_with_your_phone')}
          </span>
        </Link>
      </p>
      <div className='flex-grow'></div>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx: any) => {
  const isCustomerLoggedIn = JSON.parse(nookies.get(ctx)?.isCustomerLoggedIn || 'false');
  if (isCustomerLoggedIn) {
    serverSideRedirect(ctx, '/');
    return { props: {} };
  }
  return {
    props: {
      layout: {
        isLogin: true,
      },
      ...(await serverSideTranslations(ctx.locale, ['common'])),
    },
  };
};

export default Login;
